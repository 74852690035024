<template>
  <div class="ui-loading">
    <div class="loader-element">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <slot name="default">
        <h1 class="loader-label">{{ label }}</h1>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-loading',

  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.ui-loading {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 300px;

  .loader-element {
    width: 200px;
    height: 200px;

    text-align: center;
  }

  .loader-label {
    margin: 0;
    padding: 0;
    font-weight: normal;

    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
}

// https://loading.io/css/
.lds-ellipsis {
  position: relative;
  margin: auto;
  width: 100px;
  height: 48px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;

  background-color: var(--ui-color-primary, #999);

  animation-timing-function: ease !important;
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis-grow 0.5s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis-move 0.5s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis-move 0.5s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis-move 0.5s infinite;
}

.lds-ellipsis div:nth-child(5) {
  left: 64px;
  animation: lds-ellipsis-move 0.5s infinite;
}
.lds-ellipsis div:nth-child(6) {
  left: 83px;
  animation: lds-ellipsis-shrink 0.5s infinite;
}

@keyframes lds-ellipsis-grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes lds-ellipsis-shrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes lds-ellipsis-move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>